import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import Layout from '../components/Layout/Layout'
import Navigation from '../components/Navigation/Navigation'
import Seo from '../components/Seo/Seo'
import { useElementOnScreen } from '../hooks/useElementOnScreen'

import '../scss/pages/index.scss'

import LongArrow from '../assets/images/icons/arrow-long.svg';
import FacebookLogo from '../assets/images/icons/logo_facebook.svg';
import GoogleLogo from '../assets/images/icons/logo_google.svg';
import LinkedinLogo from '../assets/images/icons/logo_linkedin.svg';
import UmbrellaIcon from '../assets/images/icons/umbrella.svg';
import MoneyUpIcon from '../assets/images/icons/money-up.svg';
import EmployeesIcon from '../assets/images/icons/employees.svg';
import LightBulbIcon from '../assets/images/icons/lightbulb.svg';
import PeopleIcon from '../assets/images/icons/people.svg';
import ShieldIcon from '../assets/images/icons/shield.svg';
import SicknessIcon from '../assets/images/icons/sickness.svg';
import ToddlerIcon from '../assets/images/icons/toddler.svg';
import FreelanceIcon from '../assets/images/icons/freelance.svg';
import SmallCompanyIcon from '../assets/images/icons/small-company.svg';
import BigCompanyIcon from '../assets/images/icons/big-company.svg';
import HowBuy from '../assets/images/backgrounds/hands-from-homepage.jpg'
import RankingSlide from '../components/RankingSlide/RankingSlide';
import FaqCollapse from '../components/FaqCollapse/FaqCollapse';
import { toggleCollapse } from '../scripts/scripts';
import Posts from '../components/Posts/Posts';
import config from '../../config.json';
import ReferrerLink from '../components/ReferrerLink/ReferrerLink'

const IndexPage = () => {

  const data = useStaticQuery(query);
  const seo = data.wpPage.seo;

  const isSSR = typeof window === 'undefined'

  const [contentRef, contentRefIsVisible] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  })

  return (
    <Layout>
      <Seo title={seo.title} description={seo.metaDesc} keywords={seo.focuskw} pathname="/" />
      <div className="header">
        <Navigation/>
        <div className="custom-container pt-30">
          <div className="row">
            <div className="col-lg-7 col-sm-12 col-xs-12">
              <h1 className="text-white title pt-45">Porównaj <span className="orange-text">ubezpieczenia</span> <br />dla pracowników i firm</h1>
              <p className="paragraph text-white pt-30 mb-0">Polisa już <span className="orange-text">od 1. pracownika</span> lub dla <span className="orange-text">osoby prowadzącej<br />działalność</span>. Poznaj cenę i dopasuj zakres z naszym doradcą.</p>
              <div className="cta-btns-group pt-30">
                
                <ReferrerLink activeClass="cta-radius referrer-link" text="Porównaj oferty"/>
                
                <a href="/ranking-ubezpieczen/" className="cta">Poznaj ranking ubezpieczeń <img className="arrow" src={LongArrow} alt="Poznaj ranking ubezpieczeń" title="Poznaj ranking ubezpieczeń" /></a>
              </div>
              <p className="manatee-text social-links pt-50">Sprawdź, co o nas mówią</p>
              <div className="social-container">
                <a title="LeadGuru" href="https://www.leadguru.pl/" target="_blank">
                  <img src={GoogleLogo} alt="Google" title="Google"></img>
                </a>
                <a title="Facebook" href="https://www.facebook.com/UbezpieczeniePracownikow/" target="_blank">
                  <img src={FacebookLogo} alt="Facebook" title="Facebook"></img>
                </a>
                {/* <a href="">
                  <img src={LinkedinLogo} alt="Linkedin" title="Linkedin"></img>
                </a> */}
              </div>
            </div>
          </div>
          <div className="persons-section"></div>
        </div>
      </div>

      <div id="insurance-compare" className="pt-70 pb-70">
        <div className="custom-container pb-50">
          <span className="header-tile mb-30">Porównaj ubezpieczenia</span>
          <h2 className="container-title mb-0 text-center">Wybierz ubezpieczenie dla swojej firmy</h2>
          <p className="container-paragraph pt-30 mb-0 text-center">Pracownicze ubezpieczenia grupowe niezmiennie od wielu lat cieszą się na naszym rynku żywym zainteresowaniem. Dla milionów pracowników i właścicieli firm stanowią one podstawowe i często jedyne zabezpieczenie na wypadek zgonu, choroby, wypadku bądź innych trudnych sytuacji.<br />Dobre ubezpieczenie grupowe stanowi korzyść zarówno dla pracowników, jak i pracodawców. Polisa grupowa to jeden z cenionych dodatków pozapłacowych, sprzyjających budowaniu więzi z zakładem pracy. Zalety właściwie dobranego produktu dostrzeżone zostaną bardzo szybko. Wykorzystaj to!
          </p>
          <ReferrerLink activeClass="cta-radius mobile-cta referrer-link" title="Porównaj oferty" text="Porównaj oferty" />
          
        </div>
        <RankingSlide/>  
      </div>

      <div ref={contentRef}>
      {contentRefIsVisible && !isSSR && (
          <React.Suspense fallback={<div className="loader"></div>}>
            <div id="employerBenefits" className="pb-70 pt-70 header">
              <div className="custom-container pb-70">
                <span className="header-tile mb-30">Co zyskujesz jako pracodawca</span>
                <h2 className="container-title mb-0 text-center">Polisa grupowa to korzyści dla pracodawcy</h2>
                <p className="container-paragraph pt-30 mb-0 text-center">Czołowym zagadnieniem pozostaje tu fakt, że polisa grupowa, zapewniająca pracownikowi ochronę na korzystnych warunkach, sprzyja jego trwalszemu związaniu z miejscem zatrudnienia. To ważne w dobie rosnących wyzwań w obszarze gospodarowania zasobami ludzkimi. Ale to nie wszystko! Wydatki na ubezpieczenia grupowe stanowią dla pracodawcy koszt, który może uwzględnić w swoich rozliczeniach podatkowych. Z kolei w razie zgonu uczestnika polisy, świadczenie z niej może częściowo albo całkowicie zastąpić odprawę pośmiertną.</p>
              </div>
              <div className="container mt-50">
                <div className="row">
                  <div className="col-lg-3 col-xs-6">
                    <div className="box">
                      <img className="icon" src={UmbrellaIcon} alt="Przedsiębiorstwo dbające o pracowników jest atrakcyjne" title="Przedsiębiorstwo dbające o pracowników jest atrakcyjne" />
                      <h3 className="benefits-title">Przedsiębiorstwo dbające o pracowników <br/> jest atrakcyjne</h3>
                      <p className="benefits-description">Oferta pracy, której towarzyszy bogaty pakiet socjalny - w tym możliwość dołączenia do dobrej polisy grupowej - zawsze budzi większe zainteresowanie.</p>
                      <ReferrerLink activeClass="cta-radius mobile-cta referrer-link" title="Porównaj oferty" text="Porównaj oferty" />
                    </div>
                  </div>
                  <div className="col-lg-3 col-xs-6">
                    <div className="box">
                      <img className="icon" src={MoneyUpIcon} alt="Firma uzyskuje korzyści podatkowe" title="Firma uzyskuje korzyści podatkowe" />
                      <h3 className="benefits-title">Firma uzyskuje<br />korzyści podatkowe</h3>
                      <p className="benefits-description">Uiszczone przez pracodawcę składki z tytułu umów grupowego ubezpieczenia na życie na rzecz pracowników zaliczają się do kosztów uzyskania przychodu.</p>
                      <ReferrerLink activeClass="cta-radius mobile-cta referrer-link" title="Porównaj oferty" text="Porównaj oferty" />
                    </div>
                  </div>
                  <div className="col-lg-3 col-xs-6">
                    <div className="box">
                      <img className="icon" src={EmployeesIcon} alt="Rotacja kadr ulega obniżeniu" title="Rotacja kadr ulega obniżeniu" />
                      <h3 className="benefits-title">Rotacja kadr<br />ulega obniżeniu</h3>
                      <p className="benefits-description">Dobrze skonfigurowany zakres ochrony, która może obejmować także bliskich pracownika, sprzyja utrzymaniu jego trwałej więzi z zakładem pracy.</p>
                      <ReferrerLink activeClass="cta-radius mobile-cta referrer-link" title="Porównaj oferty" text="Porównaj oferty" />
                    </div>
                  </div>
                  <div className="col-lg-3 col-xs-6">
                    <div className="box">
                      <img className="icon" src={LightBulbIcon} alt="Załoga jest zmotywowana i efektywna" title="Załoga jest zmotywowana i efektywna" />
                      <h3 className="benefits-title">Załoga jest<br />zmotywowana i efektywna</h3>
                      <p className="benefits-description">Pracownicy tworzący trwały zespół łatwiej identyfikują się z firmą i pozostają zdolni do wydajnej pracy.</p>
                      <ReferrerLink activeClass="cta-radius mobile-cta referrer-link" title="Porównaj oferty" text="Porównaj oferty" />

                      <ReferrerLink activeClass="cta-radius mobile-cta referrer-link" title="Porównaj oferty" text="Porównaj oferty" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-container"></div>
            </div>

            <div id="employeesBenefits" className="pt-70 pb-70">
              <div className="custom-container pb-50">
                <span className="header-tile mb-30">Korzyści w pytaniach i odpowiedziach</span>
                <h2 className="container-title mb-0">Najważniejsze korzyści dla <br />Twojego pracownika</h2>
                <p className="container-paragraph pt-30 mb-0">Pracodawca korzysta na ubezpieczeniu pracowników przede wszystkim dlatego, że zyskują także oni. Pracownicza polisa grupowa jest rozwiązaniem stosunkowo tanim, więc nawet opłacana przez pracowników stanowi rozwiązanie atrakcyjne. Rozproszenie ryzyka sprawia w dodatku, że mogą do niej przystąpić także ci, dla których znalezienie przystępnej oferty indywidualnej jest problematyczne. Ponadto ubezpieczenia pracownicze mogą obejmować bardzo bogaty pakiet rozszerzeń i dodatkowych, przydatnych świadczeń.</p>
              </div>
              <div className="custom-container">
                <div className="box">
                  <img className="icon" src={ShieldIcon} alt="Ochrona ubezpieczeniowa w dobrej cenie" title="Ochrona ubezpieczeniowa w dobrej cenie" />
                  <div className="benefits-title">Ochrona ubezpieczeniowa w dobrej cenie</div>
                  <div className="benefits-description">Pracownicze ubezpieczenia grupowe dystrybuowane się inaczej niż polisy indywidualne, co przekłada się niższe koszty. W efekcie mimo wspólnego kosztu ryzyka nadal jest to niedrogi, dostępny dla każdego produkt.</div>
                </div>
                <div className="box">
                  <img className="icon" src={PeopleIcon} alt="Każdy może dołączyć do polisy pracowniczej" title="Każdy może dołączyć do polisy pracowniczej" />
                  <div className="benefits-title">Każdy może dołączyć do polisy pracowniczej</div>
                  <div className="benefits-description">Ryzyko ubezpieczeniowe dotyczy całej grupy, a nie poszczególnych uczestników polisy. To dzięki temu możliwość korzystania z ochrony mają także pracownicy starsi, obciążeni większym prawdopodobieństwem kłopotów zdrowotnych.</div>
                </div>
                <div className="box">
                  <img className="icon" src={ToddlerIcon} alt="Atrakcyjne świadczenia dodatkowe" title="Atrakcyjne świadczenia dodatkowe" />
                  <div className="benefits-title">Atrakcyjne świadczenia dodatkowe</div>
                  <div className="benefits-description">Grupowe polisy na życie bardzo często zapewniają wypłatę środków także w razie takich zdarzeń, jak np. urodzenie dziecka. Nie należy takich świadczeń traktować jako najważniejszych, jednak z punktu widzenia pracownika jest to korzyść.</div>
                </div>
                <div className="box">
                  <img className="icon" src={SicknessIcon} alt="Szeroki zakres ochrony zdrowia" title="Szeroki zakres ochrony zdrowia" />
                  <div className="benefits-title">Szeroki zakres ochrony zdrowia</div>
                  <div className="benefits-description">Oprócz zgonu polisa grupowa może objąć także szeroki wachlarz ryzyk z zakresu NNW i ochrony zdrowia. Przy należytych sumach ubezpieczenia będzie wówczas stanowiła realne wsparcie w trudnych sytuacjach.</div>
                </div>
                <ReferrerLink activeClass="cta-radius mobile-cta mx-auto referrer-link" title="Porównaj oferty" text="Porównaj oferty" />
              </div>
              <div className="bg-section"></div>
            </div>

            <div id="activityType" className="pt-70 pb-70">
              <div className="custom-container pb-50">
                <span className="header-tile mb-30">Wybór dla każdej działalności</span>
                <h2 className="container-title mb-0">Ubezpieczenia grupowe<br />dla wszystkich przedsiębiorców</h2>
                <p className="container-paragraph pt-30 mb-0">Obecne na rynku podmioty gospodarcze cechuje jak wiadomo ogromna różnorodność. Dotyczy to ich wielkości, postaci organizacyjnych, charakteru sposobu zatrudniania personelu i innych cech wynikających ze skali i rodzaju działalności. Ubezpieczenia grupowe pracowników da się dobrać do potrzeb ich wszystkich. Niejednokrotnie ta sama polisa grupowa może przybierać w szczegółach różne postaci, wedle potrzeb ustalonych przez właściciela i załogę. Ponadto wielu ubezpieczycieli ma w ofercie szerszy wybór produktów tego rodzaju.</p>
              </div>
              <div className="container">
                <div className="row display-flex">
                  <div className="col-lg-4 col-xs-12">
                    <div className="box">
                      <img className="icon" src={FreelanceIcon} alt="Własna działalność gospodarcza" title="Własna działalność gospodarcza" />
                      <h3 className="title">Własna działalność gospodarcza</h3>
                      <div>

                        <div class="collapse-box" onClick={toggleCollapse}>
                            <div className="collapse-title">Ubezpieczenie na życie zdrowie</div>
                            <div className="collapse-content d-none">Typowa ochrona ubezpieczeniowa potrzebna jest każdemu, niezależnie od rodzaju wykonywanej pracy.</div>
                        </div>
                        <div class="collapse-box" onClick={toggleCollapse}>
                            <div className="collapse-title">Wsparcie w razie własnej niezdolności do pracy</div>
                            <div className="collapse-content d-none">Osobista odpowiedzialność za własny biznes rodzi ryzyka dla rodziny, które warto ograniczyć.</div>
                        </div>
                        <div class="collapse-box" onClick={toggleCollapse}>
                            <div className="collapse-title">Możliwość objęcia ochroną ubezpieczeniową najbliższych</div>
                            <div className="collapse-content d-none">Ubezpieczenie zawsze może i powinno służyć ochronie bytu rodziny, także bezpośrednio jej członków.</div>
                        </div>
                      
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-xs-12">
                    <div className="box">
                      <img className="icon" src={SmallCompanyIcon} alt="Przedsiębiorstwa małe i średnie" title="Przedsiębiorstwa małe i średnie" />
                      <h3 className="title">Przedsiębiorstwa małe i średnie</h3>

                      <div>

                        <div class="collapse-box" onClick={toggleCollapse}>
                            <div className="collapse-title">Grupy mogące liczyć zaledwie kilka osób</div>
                            <div className="collapse-content d-none">To zależy od konkretnego produktu. Spotykane są oferty pozwalające ubezpieczyć trzech, dwóch, a nawet jednego pracownika.</div>
                        </div>
                        <div class="collapse-box" onClick={toggleCollapse}>
                            <div className="collapse-title">Bogata i atrakcyjna cenowo oferta nawet dla niewielkiej firmy</div>
                            <div className="collapse-content d-none">Rozbudowana oferta dodatkowych ubezpieczeń to dziś typowa cecha zarówno polis indywidualnych jak i grupowych.</div>
                        </div>
                        <div class="collapse-box" onClick={toggleCollapse}>
                            <div className="collapse-title">Zakresy dostosowane do potrzeb konkretnego podmiotu</div>
                            <div className="collapse-content d-none">Konkretną postać polisy grupowej można negocjować przed zawarciem umowy i dostosować ją zwłaszcza do typowych dla danej branży zagrożeń.</div>
                        </div>
                      
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-xs-12">
                    <div className="box">
                      <img className="icon" src={BigCompanyIcon} alt="Średnie i duże firmy" title="Średnie i duże firmy" />
                      <h3 className="title">Średnie i duże<br />firmy</h3>

                      <div>

                        <div class="collapse-box" onClick={toggleCollapse}>
                            <div className="collapse-title">Szeroki wachlarz ryzyk i świadczeń dodatkowych</div>
                            <div className="collapse-content d-none">Duża załoga pozwala zwykle zachować wysoki stopień partycypacji, a więc i warunki bardzo atrakcyjne dla obu stron umowy.</div>
                        </div>
                        <div class="collapse-box" onClick={toggleCollapse}>
                            <div className="collapse-title">Podgrupy o różnych warunkach i zakresach ubezpieczenia</div>
                            <div className="collapse-content d-none">Odpowiednio przygotowana oferta ubezpieczenia umożliwia niemalże indywidualizowanie zakresu ochrony.</div>
                        </div>
                        <div class="collapse-box" onClick={toggleCollapse}>
                            <div className="collapse-title">Oferty dla szczególnie cennych pracowników bądź np. członków zarządów</div>
                            <div className="collapse-content d-none">Można zapewnić części załogi i współpracowników ochronę z wysokimi sumami ubezpieczenia, na jeszcze lepszych warunkach.</div>
                        </div>
                      
                      </div>
                    </div>
                  </div>
                </div>

                <ReferrerLink activeClass="cta-radius mobile-cta mx-auto referrer-link" title="Porównaj oferty" text="Porównaj oferty" /> 
              </div>
              <div className="bg-container"></div>
            </div>
    

            <div id="faq" className="pt-70 pb-70">
              <div className="custom-container pb-50">
                <span className="header-tile mb-30">FAQ</span>
                <h2 className="container-title mb-0">Odpowiadamy na najczęściej zadawane pytania</h2>
                <p className="container-paragraph pt-30 mb-0">Choć grupowe ubezpieczenia pracownicze należą do popularnych produktów, wiele jest jeszcze przedsiębiorstw - zwłaszcza małych - w których nie korzysta się z tego rozwiązania i nie każdemu jest ono dobrze znane. Sytuacja na rynku pracy sprawia jednak, że z pewnością będzie się to zmieniać - każdy sposób na podniesienie atrakcyjności oferty zatrudnienia i zatrzymanie pracownika w firmie wart jest obecnie rozważenia. Grupowe ubezpieczenie pracowników należy do najlepszych. Zajmijmy się więc kilkoma budzącymi wątpliwości kwestiami.</p>
              </div>
              <div className="custom-container">
                <FaqCollapse />
              </div>
    
              <ReferrerLink activeClass="cta-radius mobile-cta mx-auto referrer-link" title="Porównaj oferty" text="Porównaj oferty" />
              
              <div class="bg-container"></div>
            </div>

            <div id="howBuy">
              <div className="custom-container">
                  <div className="box">
                    <img className="bg-img" src={HowBuy} title="Jak kupić ubezpieczenie pracowników?" alt="Jak kupić ubezpieczenie pracowników?"/>
                    <h2 class="container-title">Jak kupić ubezpieczenie pracowników?</h2>
                    <ul className="list">
                      <li>Wypełnij formularz<br /><span>potrzeb Twojej firmy</span></li>
                      <li>Wysłuchaj oferty i odbierz maila<br />z <span>dopasowanymi propozycjami</span></li>
                      <li>Wybierz najlepszą i oszczędź <span>nawet<br />39%</span> kosztów polisy</li>
                    </ul>
                    <div className="cta-btns-group pt-30">
                      <ReferrerLink activeClass="cta-radius referrer-link" text="Dopasuj ubezpieczenie" />

                      <a href="/ranking-ubezpieczen/" className="cta">Sprawdź ranking <img className="arrow" src={LongArrow} alt="Sprawdź ranking" title="Sprawdź ranking" /></a>
                    </div>
                  </div>
              </div>
            </div>

            <Posts content="Już od wielu lat zajmujemy się zarówno samą tematyką ubezpieczeniową, jak i skuteczną komunikacją pomiędzy klientami, a agentami i towarzystwami ubezpieczeniowymi. Ubezpieczenia grupowe pracowników to obszerna dziedzina. Dokładamy starań, aby poszczególne składające się na nią zagadnienia przybliżać w przystępny i zarazem wyczerpujący sposób. Zarówno pracownikom, jak i pracodawcom poszukującym rozwiązań, dzięki którym zyskają w ich oczach ważny atut. Zapraszamy do częstych odwiedzin na naszym blogu." />
          </React.Suspense>
        )}
      </div>
    </Layout>
  );
}

export default IndexPage

const query = graphql`
  {
    wpPage(slug: {eq: "strona-glowna"}) {
      seo {
        metaDesc
        title
        focuskw
      }
    }
  }
`
