import { graphql, useStaticQuery } from 'gatsby'
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getImage } from 'gatsby-plugin-image'
import "./slide.scss"
import ReferrerLink from '../ReferrerLink/ReferrerLink';

import Arrow from '../../assets/images/icons/bottom-arrow.svg'

export default function RankingSlide() {
  const data = useStaticQuery(query);
  const ranking = data.allWpPost.edges;

  var settings = {
    className: "slider variable-width",
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    customWidth: true,
    variableWidth: true,
    nextArrow: <img src={Arrow} alt="Strzałka" title="Strzałka" />,
    prevArrow: <img src={Arrow} alt="Strzałka" title="Strzałka" />
  };

  return (
    <div className="slider">
      <Slider {...settings}>
        {ranking.map((post, index) => {
          const image = getImage(post.node.ranking.logo.localFile)
          return <div className="slide-box">
                    <div className="content">
                      <div className="content-box">
                        <p className="company-name">{post.node.title}</p>
                        <img className="company-logo" src={image.images.fallback.src} alt={post.node.title} title={post.node.title} />
                        <div className="company-description" dangerouslySetInnerHTML={{ __html: post.node.ranking.zajawka }}></div>
                      </div>
                    </div>
                    <div className="line"></div>
                    <div className="content">
                      <ul className="list">
                        {post.node.ranking.cecha1 && (
                          <li>{post.node.ranking.cecha1}</li>
                        )}
                        {post.node.ranking.cecha2 && (
                          <li>{post.node.ranking.cecha2}</li>
                        )}
                        {post.node.ranking.cecha3 && (
                          <li>{post.node.ranking.cecha3}</li>
                        )}
                        {post.node.ranking.cecha4 && (
                          <li>{post.node.ranking.cecha4}</li>
                        )}
                      </ul>
                    </div>
                    <div className="price-box"><span><small>od</small> {post.node.ranking.cena} <small>zł / msc</small></span></div>
                    <div className="buttons-container">
                      {post.node.content && <a href={`/ranking-ubezpieczen/${post.node.slug}/`} className="cta-radius-dark details-link" title={post.node.title}>Poznaj szczegóły</a>}

                      <ReferrerLink activeClass="cta-radius calculator-link referrer-link" text="Porównaj" title="Porównaj" />
                    </div>
                  </div>
        })}
      </Slider>
    </div>
  );
}

export const query = graphql`
  {
    allWpPost(
      filter: {categories: {nodes: {elemMatch: {name: {eq: "Ranking"}}}}}
      sort: {fields: ranking___sredniaOcena, order: DESC}
    ) {
      edges {
        node {
          content
          title
          ranking {
            cena
            fieldGroupName
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 350)
                }
              }
            }
            zajawka
            cecha1
            cecha2
            cecha3
            cecha4
          }
          slug
        }
      }
    }
  }
`

