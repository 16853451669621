import { graphql, useStaticQuery } from 'gatsby'
import React from "react";
import { toggleCollapse } from '../../scripts/scripts';

import "./faq-collapse.scss"

export default function FaqCollapse() {
    const data = useStaticQuery(query);
    const faq = data.allWpPost.edges;

    return (
        <div>
            {faq.map((post, index) => {
            return <div class="collapse-box" onClick={toggleCollapse}>
                    <div className="collapse-title">{post.node.title}</div>
                    <div className="collapse-content d-none" dangerouslySetInnerHTML={{ __html: post.node.content }}></div>
                </div>
            })}
        </div>
    );
}

export const query = graphql`
{
    allWpPost(filter: {categories: {nodes: {elemMatch: {name: {eq: "Faq"}}}}}) {
        edges {
            node {
                content
                title
            }
        }
    }
}
`

